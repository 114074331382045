/* You can add global styles to this file, and also import other style files */
body{
	font-family: sans-serif;
	font-size: 14px;
}

.form-group {
	display: grid;
  	width: 50%;
	padding: 10px;
}

.form-error {
	font-size: 12px;
	color: red;
}

.bg-color {
	background-color: #05386b !important;
	color: #fff;
}

.d-flex {
	display: flex;
}

.f-700 {
	font-weight: 700;
}

a {
	color: #2196F3;
	cursor: pointer;
}

.cursor-pointer {
	cursor: pointer;
}

.fs-17 {
	font-size: 17px !important;
}

.fs-12 {
	font-size: 12px !important;
}

.button-xs {
	font-size: 14px !important;
	height: 25px;
    width: auto;
}

.float-right {
	float: right;
}

.error-text {
	color: red;
	font-size: 14px;
	padding: 5px;
}

.p-component {
	font-size: 14px !important;
}

.upper-right {
    width: auto;
    float: right;
    margin-right: 50px;
    z-index: 1000;
    position: absolute;
	right: 0;
}

.message-position {
	position: absolute;
    z-index: 999;
    right: 0;
}

.error-dialog .p-dialog .p-dialog-header .p-dialog-header-icon {
    color:#fff;
}

.error-dialog .p-dialog .p-dialog-header {
    padding: 0rem 1.5rem !important;
    background-color: #d0402e;
    color: #fff;
}

.error-dialog .p-dialog .p-dialog-footer {
	padding: 0.5rem 1rem;
}

.create-form .p-dialog .p-dialog-header{
    background: aliceblue
}

.p-multiselect-footer {
	text-align: center;
	padding: 5px;
}

.p-component:disabled {
	opacity: 0.6;
	cursor: not-allowed !important;
}

.p-calendar .p-datepicker {
	z-index: 9999;
	min-width: 50% !important;
	height: 400px !important;
}

.p-datepicker table td {
	padding: 2px !important;
}

@media all and (max-width:768px) {
	.p-datatable-scrollable .p-datatable-wrapper {
		max-height: 65vh !important;
	}	
}

@media all and (min-width:768px) and (min-height:520px) {
	.p-datatable-scrollable .p-datatable-wrapper {
		max-height: 65vh !important;
	}	
}

@media all and (min-width:768px) and (min-height:768px) {
	.p-datatable-scrollable .p-datatable-wrapper {
		max-height: 70vh !important;
	}	
}

@media all and (min-width:768px) and (min-height:1024px) {
	.p-datatable-scrollable .p-datatable-wrapper {
		max-height: 77vh !important;
	}	
}

@media all and (min-width:768px) and (min-height:1366px) {
	.p-datatable-scrollable .p-datatable-wrapper {
		max-height: 82vh !important;
	}	
}

@media all and (min-width:1200px) and (min-height:1024px) {
	.p-datatable-scrollable .p-datatable-wrapper {
		max-height: 78vh !important;
	}	
}

@media all and (min-width:1200px) and (min-height:1366px) {
	.p-datatable-scrollable .p-datatable-wrapper {
		max-height: 65vh !important;
	}	
}